<div class="contents">
  <div class="row">
    <div class="col-12 center">
      <div
        class="semi-circle"
        [ngClass]="{
          'semi-circle-small': isSmall,
          'semi-circle-large': !isSmall
        }"
      ></div>
      <h4
        id="contactNameId"
        name="contactName"
        aria-label="contact name"
        class="name"
      >
        JOEY WILLS
      </h4>
    </div>
    <div class="col-12 center title">
      <strong id="contactTitleId" aria-label="contact title"
        >FULL-STACK WEB DEVELOPER</strong
      >
    </div>
    <div class="col-12 center">
      <img
        id="profilePicJoeyWillsId"
        name="profilePicJoeyWills"
        alt="Profile picture of Joey Wills"
        aria-label="Profile picture of Joey Wills"
        class="profile-pic"
        src="assets/photos/profile.jpg"
      />
    </div>
    <div class="col-12 center">
      <a
        id="contactEmailId"
        aria-label="contact email"
        class="phone-email"
        href="{{'mailto:' + emailString}}"
        >{{emailString}}</a
      >
    </div>
    <div class="col-12 center">
      <button
        id="downloadResPDFId"
        aria-label="Download Resume Button"
        class="btn-res"
        (click)="downloadResume()"
      >
        Download Resume
        <img
          id="pdficonId"
          name="pdficon"
          alt="PDF Icon"
          class="pdf-icon"
          src="assets/icons/pdf-file-icon.svg"
        />
      </button>
    </div>
  </div>
</div>
