<div>
  <h5
    id="otherInfoSkillsSecTitleId"
    name="otherInfoSkillsSecTitle"
    aria-label="Section Title"
    class="sec-title"
  >
    OTHER INFORMATION AND SKILLS
  </h5>
</div>
<hr class="hor" />
<div class="row">
  <div class="col-12 col-sm-4 sub-title">
    <div class="card">
      <div class="container">
        <strong
          id="oSSubSecTitleId"
          name="oSSubSecTitle"
          aria-label="Sub Section Title"
          >Operating Systems</strong
        >
        <br />
        <div class="row">
          <div
            *ngIf="otherInfoSkills.operatingSystems.advanced"
            class="col col-sm-12 skill-sec"
          >
            <div
              id="oSSubSecAdvTitleId"
              name="oSSubSecAdvTitle"
              aria-label="Skill level"
              class="adv"
            >
              ADVANCED
              <hr class="divider" />
            </div>
            <div
              [id]="'skill' + 'OS' + advSkillOS + 'Id'"
              name="'skill' + 'OS' + advSkillOS"
              aria-label="Operating System"
              *ngFor="
                let advSkillOS of otherInfoSkills.operatingSystems.advanced
              "
            >
              {{ advSkillOS }}
            </div>
          </div>
          <div
            *ngIf="otherInfoSkills.operatingSystems.intermediate"
            class="col col-sm-12 skill-sec"
          >
            <div
              id="oSSubSecIntTitleId"
              name="oSSubSecIntTitle"
              aria-label="Skill level"
              class="int"
            >
              INTERMEDIATE
              <hr class="divider" />
            </div>
            <div
              [id]="'skill' + 'OS' + intSkillOS + 'Id'"
              name="'skill' + 'OS' + intSkillOS"
              aria-label="Operating System"
              *ngFor="
                let intSkillOS of otherInfoSkills.operatingSystems.intermediate
              "
            >
              {{ intSkillOS }}
            </div>
          </div>
          <div
            *ngIf="otherInfoSkills.operatingSystems.beginner"
            class="col col-sm-12 skill-sec"
          >
            <div
              id="oSSubSecBegTitleId"
              name="oSSubSecBegTitle"
              aria-label="Skill level"
              class="beg"
            >
              BEGINNER
              <hr class="divider" />
            </div>
            <div
              [id]="'skill' + 'OS' + begSkillOS + 'Id'"
              name="'skill' + 'OS' + begSkillOS"
              aria-label="Operating System"
              *ngFor="
                let begSkillOS of otherInfoSkills.operatingSystems.beginner
              "
            >
              {{ begSkillOS }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-4 sub-title">
    <div class="card">
      <div class="container">
        <strong
          id="softwareSubSecTitleId"
          name="softwareSubSecTitle"
          aria-label="Sub Section Title"
          >Software</strong
        >
        <div class="row">
          <div
            *ngIf="otherInfoSkills.software.advanced"
            class="col col-sm-12 skill-sec"
          >
            <div
              id="softwareSubSecAdvTitleId"
              name="softwareSubSecAdvTitle"
              aria-label="Skill level"
              class="adv"
            >
              ADVANCED
              <hr class="divider" />
            </div>
            <div
              [id]="'skill' + 'Software' + advSkillSoft + 'Id'"
              name="'skill' + 'Software' + advSkillSoft"
              aria-label="Operating System"
              *ngFor="let advSkillSoft of otherInfoSkills.software.advanced"
            >
              {{ advSkillSoft }}
            </div>
          </div>
          <div
            *ngIf="otherInfoSkills.software.intermediate"
            class="col col-sm-12 skill-sec"
          >
            <div
              id="softwareSubSecIntTitleId"
              name="softwareSubSecIntTitle"
              aria-label="Skill level"
              class="int"
            >
              INTERMEDIATE
              <hr class="divider" />
            </div>
            <div
              [id]="'skill' + 'Software' + intSkillSoft + 'Id'"
              name="'skill' + 'Software' + intSkillSoft"
              aria-label="Operating System"
              *ngFor="let intSkillSoft of otherInfoSkills.software.intermediate"
            >
              {{ intSkillSoft }}
            </div>
          </div>
          <div
            *ngIf="otherInfoSkills.software.beginner"
            class="col col-sm-12 skill-sec"
          >
            <div
              id="softwareSubSecBegTitleId"
              name="softwareSubSecBegTitle"
              aria-label="Skill level"
              class="beg"
            >
              BEGINNER
              <hr class="divider" />
            </div>
            <div
              [id]="'skill' + 'Software' + begSkillSoft + 'Id'"
              name="'skill' + 'Software' + begSkillSoft"
              aria-label="Operating System"
              *ngFor="let begSkillSoft of otherInfoSkills.software.beginner"
            >
              {{ begSkillSoft }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-4 sub-title">
    <div class="card">
      <div class="container">
        <strong
          id="busKnowSubSecTitleId"
          name="busKnowSubSecTitle"
          aria-label="Sub Section Title"
          >Business Knowledge</strong
        >
        <div
          [id]="'skill' + 'Business' + knowledge + 'Id'"
          name="'skill' + 'Business' + knowledge"
          aria-label="Business Knowledge"
          *ngFor="let knowledge of otherInfoSkills.businessKnowledge"
        >
          {{ knowledge }}
        </div>
      </div>
    </div>
  </div>
</div>
