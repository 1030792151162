<div class="page-container">
  <!-- header -->
  <app-header (navBarButtonClicked)="navBarButtonClickedRev()"></app-header>
  <app-nav-bar-desktop
    *ngIf="isDesktopOrBigger && isNavbarOpen"
    (navItemSendToApp)="closeNavBar()"
  >
  </app-nav-bar-desktop>
  <app-nav-bar-mobile
    *ngIf="!isDesktopOrBigger && isNavbarOpen"
    (navItemSendToApp)="closeNavBar()"
  >
  </app-nav-bar-mobile>
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
  <!-- footer -->
  <app-footer></app-footer>
</div>
