<div>
  <h5
    id="keySkillsSecTitleId"
    name="keySkillsSecTitle"
    aria-label="Section Title"
    class="sec-title"
  >
    KEY SKILLS
  </h5>
</div>
<hr class="hor" />
<div class="row">
  <div class="col-12 col-sm-4 skill-sec">
    <div class="card">
      <div class="container lg-scr">
        <div
          id="langSubSecId"
          name="langSubSec"
          aria-label="Languages Sub Section"
          class="content"
        >
          <strong
            id="langSubSecTitleId"
            name="langSubSecTitle"
            aria-label="Sub Section Title"
            >Languages</strong
          >
          <hr class="divider" />
          {{ skills.languages }}.
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-4 skill-sec">
    <div class="card">
      <div class="container lg-scr">
        <div
          id="frameworksSubSecId"
          name="frameworksSubSec"
          aria-label="Frameworks Sub Section"
          class="content"
        >
          <strong
            id="frameworksSubSecTitleId"
            name="frameworksSubSecTitle"
            aria-label="Sub Section Title"
            >Frameworks</strong
          >
          <hr class="divider" />
          {{ skills.frameworks }}.
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-4 skill-sec">
    <div class="card">
      <div class="container lg-scr">
        <div
          id="softToolsSubSecId"
          name="softToolsSubSec"
          aria-label="SoftTools Sub Section"
          class="content"
        >
          <strong
            id="softToolsSubSecTitleId"
            name="softToolsSubSecTitle"
            aria-label="Sub Section Title"
            >Software/Tools</strong
          >
          <hr class="divider" />
          {{ skills.softwareTools }}.
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-12 skill-sec">
    <div class="card">
      <div class="container lg-scr">
        <div
          id="methodsSubSecId"
          name="methodsSubSec"
          aria-label="Methodologies Sub Section"
          class="content"
        >
          <strong
            id="methodsSubSecTitleId"
            name="methodsSubSecTitle"
            aria-label="Sub Section Title"
            >Methodologies</strong
          >
          <hr class="divider" />
          {{ skills.methods }}.
        </div>
      </div>
    </div>
  </div>
</div>
