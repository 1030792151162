<footer
  id="siteFooterId"
  name="siteFooter"
  aria-label="Site Footer"
  class="footer"
>
  <div class="container">
    <div class="content has-text-centered"></div>
  </div>
</footer>
